// import React, { useEffect, useRef } from 'react'
import { LinkedIn } from '@material-ui/icons'
import { GitHub } from '@material-ui/icons'
import { Twitter } from '@material-ui/icons'
import "../styles/Home.css"
// import { gsap } from 'gsap'
// import SplitType from 'split-type'
import IconAnimaiton from '../animations/IconAnimation'
import dario from "../assets/dc_pp_c.jpg" 
import dario_water from "../assets/dc_work_c.jpg" 


function Home() {

  // title animation
  // useEffect(() => {
  //   const myText = new SplitType('#title');
  //   const tl = gsap.timeline();

  //   tl.from('.char', {
  //     y:115,
  //     opacity: 0,
  //     stagger: 0.05,
  //     duration: 0.5,
  //     delay: 0.2,
  //   })
  // }, []); 

  return (
    <div className="home">
      <div className="about">
        <h2 id = "title"> DARIO CLINE </h2>
        <div 
          className = "prompt">
            <p> 
              Computer Science student at the Uni of Bath 
            </p>
            <div 
              className= "icon">
                <IconAnimaiton icon={LinkedIn} link = 'https://www.linkedin.com/in/dario-cline-b96340168/' scaleFactor={1.2} baseScale = {3}/>
                <IconAnimaiton icon={GitHub} link = 'https://github.com/IIDCII' scaleFactor={1.2} baseScale = {3}/>
                <IconAnimaiton icon={Twitter} link = 'https://twitter.com/Dario_Cline' scaleFactor={1.2} baseScale = {3}/>
            </div>
        </div>
      </div>
      <div className="container">
      <div className="content">
      <h1> ABOUT ME</h1>
      <div className="more">
        <img src={dario} alt = "img"></img>
        <p>Prospective machine learning engineer and computer science student at the <b> University of Bath </b> on track for a <b>first-class</b> degree. <br/><br/>I've grown an interest in understanding consciousness, leading me into machine learning in order to bridge the gap between algorithms and neural cognition. As a result, my appreciation for scientific research has grown and I'm excited to be involved in any type of software research and development.<br/><br/>I am also experienced in <b>video/photo editing </b> and <b>front-end </b> development.</p>
      </div>
      <div className="more" id = "right">
        <img src={dario_water} alt = "img"></img>
        <p>Beyond studying and work, I play football for various teams in London and Bath. I also spend a lot of time researching into philosophy, psychology  and religion because of my mentioned interest in consciousness. When it comes to music, Kaytranada is currently my favourite artist, but I have a broad taste. Open any of my <a href="https://music.apple.com/profile/dario_cline">playlists</a> and let me know what you think. <br/><br/>In my free time, you'll most likely find me on Twitter or outside. Feel free to <a href = "https://twitter.com/Dario_Cline"> follow</a> a real one.</p>
      </div>
      <h1> SKILLS </h1>
      <div className="skills">
        <ol className = "list">
          <li className = "item">
            <h2>Programming Languages</h2>
            <span>Python, C, Java, Javascript, Haskell, SQL, HTML, CSS</span>
          </li>
          <li className = "item">
            <h2>Software</h2>
            <span>Xcode, Vscode, Android Studio, Github, Excel, Colab, Jupyter, Adobe Cloud, Davinci Resolve</span>
          </li>
          <li className = "item">
            <h2>Libraries</h2>
            <span>Tensorflow, Pytorch, Numpy + many more</span>
          </li>
          <li className = "item">
            <h2>Other</h2>
            <span>Drivers Licence, Fluent in English, Intermediate Italian, Graphic Design, Video Editing</span>
          </li>
        </ol>
        </div>
        </div>
      </div>
    </div>
  )
}

export default Home
