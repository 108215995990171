import './App.css';
import {BrowserRouter as Router, Routes, Route } from 'react-router-dom'
import Home from './pages/Home';
import Projects from './pages/Projects';
import Experience from './pages/Experience';
import Contact from './pages/Contact';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import ProjectDisplay from './pages/ProjectDisplay';

function App() {
  return <div className="App">
    {/* route is just so that the website can have different web pages */}
    {/* so this is just the main element */}
    <Router className = "main">
      <Navbar className = "navbar"></Navbar>
      <div className = "pages">
      <Routes>
        <Route path="/" element={<Home/>}/>
        <Route path="/projects" element={<Projects/>}/>
        <Route path="/projects/:id" element={<ProjectDisplay></ProjectDisplay>}></Route>
        <Route path="/experience" element={<Experience/>}/>
        <Route path="/contact" element={<Contact/>}/>
      </Routes>
      </div>
      <Footer className ="footer"></Footer>
    </Router>
  </div>
  ;}

export default App;
