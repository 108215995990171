import React, { useRef } from 'react';
import { gsap } from 'gsap';

function IconAnimation({ icon, link, scaleFactor = 1.2 , baseScale = 1}) {
  const iconRef = useRef(null);

  // Function to handle the hover animation
  function handleIconHover() {
    const icon = iconRef.current;

    gsap.to(icon, {
      scale: scaleFactor, // Customizable scale factor for hover effect
      duration: 0.3, // Animation duration
      ease: 'power1.out', // Easing function
    });
  }

  // Function to handle the hover out animation
  function handleIconHoverOut() {
    const icon = iconRef.current;

    gsap.to(icon, {
      scale: 1, // Return to the original scale
      duration: 0.3, // Animation duration
      ease: 'power1.inOut', // Easing function
    });
  }

  return (
      <a href={link} target="_blank" rel="noopener noreferrer" onMouseEnter={handleIconHover}
      onMouseLeave={handleIconHoverOut}>
        {/* Your icon component or JSX */}
        {React.cloneElement(icon, {
          ref: iconRef,
          style: {  fontSize: `${baseScale}rem`, margin: '0.25rem'},
        })}
      </a>
  );
}

export default IconAnimation;
