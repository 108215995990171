import React from 'react'
import { useParams } from 'react-router-dom'
import { ProjectList } from '../helpers/ProjectList';
import { GitHub } from '@material-ui/icons';
import "../styles/ProjectDisplay.css"
import IconAnimaiton from '../animations/IconAnimation'
import LinkIcon from '@mui/icons-material/Link';

function ProjectDisplay() {
  const{id} = useParams();
  const project = ProjectList[id]
  return (
    <div className="project">
      <h1> {project.name}</h1>
      <img src = {project.image}></img>
      <div className= "projectdesc"> {project.desc}</div>
      <div className="icon-container">
        <div className="icon"><IconAnimaiton icon={GitHub} link = {project.ref} scaleFactor={1.2} baseScale = {3}/></div>
        {project.refex ? (
          <div className="icon">
            <IconAnimaiton icon={LinkIcon} link={project.refex} scaleFactor={1.2} baseScale = {3}/>
          </div>
        ) : null}      </div>
    </div>
  )
}

export default ProjectDisplay
