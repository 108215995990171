import React from 'react'
import { Instagram } from '@material-ui/icons';
import { Twitter } from '@material-ui/icons';
import { LinkedIn } from '@material-ui/icons';
import { GitHub } from '@material-ui/icons';
import "../styles/Footer.css";

function Footer() {
  return (
    <div className="footer">
      <div className="socialMedia">
        <LinkedIn></LinkedIn>
        <GitHub/>
        <Twitter></Twitter>
      </div>
      <p>&copy; 2024 dariocline.com</p>
    </div>
  )
}

export default Footer
