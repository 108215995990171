import React from 'react'
import bath from "../assets/bath logo.jpeg" 
import stac from "../assets/stac logo.png" 
import fl from "../assets/freelance logo.png" 
import licc from "../assets/licc logo.png" 
import st from "../assets/scholarly tuition logo.jpeg" 
import "../styles/Experience.css"

function Experience() {
  return (
    <div className = "experience">
    <h1 className = "title"> EXPERIENCE</h1>
    <div className = "alignleft"><h2 className = "exptitle"> EDUCATION</h2></div>
    <ol className = "list">
      <li className = "item" id ="itemexp">
        <img src={bath} id="expimg"></img>
        <div id = "iteminfo">
        <h2 id="expitemtitle"> University of Bath </h2>
        <span id = "expspan">Bath, UK, exp 2026</span>
        <h3> MComp (Hons) computer science & ai </h3>
        <ul className = "sublist">
          <li className = "subitem"> Machine Learning :<br/>Creating Deep Neural Network models in Python using PyTorch and TensorFlow with learning the fundamentals of supervised, unsupervised, and reinforcement learning </li>
          <li className = "subitem"> Other : <br/>coding practices, complexity, dev methodology, logic, game theory, graphics, foundations, natural language processing, etc...</li>
        </ul>
        </div>
      </li>
      <li className = "item" id ="itemexp">
        <img src={stac} id="expimg"></img>
        <div id = "iteminfo">
        <h2 id="expitemtitle"> St Thomas the Apostle College </h2>
        <span id = "expspan">London, UK, 2012 - 2019</span>
        <h3> GCSE's and A-levels </h3>
        <ul className = "sublist">
          <li className = "subitem"> Achieved 4A*s, 4As and 4Bs in GCSE's </li>
          <li className = "subitem"> Achieved A*AB in Maths Further Maths and Physics</li>
        </ul>
        </div>
      </li>
    </ol>
    <div className = "alignleft"><h2 className = "exptitle"> WORK</h2></div>
    <ol className = "list">
      <li className = "item" id ="itemexp">
        <img src={fl} id="expimg"></img>
        <div id = "iteminfo">
        <h2 id="expitemtitle"> Freelance </h2>
        <span id = "expspan">London, UK, 2022-current</span>
        <h3> Video Editor </h3>
        <ul className = "sublist">
          <li className = "subitem"> Edit videos for various types of clients </li>
          <li className = "subitem"> Help provide social media advice </li>
        </ul>
        </div>
      </li>
      <li className = "item" id ="itemexp">
        <img src={licc} id="expimg"></img>
        <div id="iteminfo">
        <h2 id="expitemtitle"> LICC </h2>
        <span id = "expspan">London, UK, 2020-2022</span>
        <h3> Social Media Editor </h3>
        <ul className = "sublist">
          <li className = "subitem"> Edited promotional videos </li>
          <li className = "subitem"> Helped set up and run weekly livestreams </li>
          <li className = "subitem"> Reformatting website </li>
        </ul>
        </div>
      </li>
      <li className = "item" id ="itemexp">
        <img src={stac} id="expimg"></img>
        <div id ="iteminfo">
        <h2 id="expitemtitle"> St Thomas the Apostle College </h2>
        <span id = "expspan">London, UK, 2019 - 2020</span>
        <h3> TA/Teacher </h3>
        <ul className = "sublist">
          <li className = "subitem"> Teaching assistant for years 7 to 11 </li>
          <li className = "subitem"> Intervention lessons in Physics and Further Maths for years 12 and 13</li>
        </ul>
        </div>
      </li>
      <li className = "item" id ="itemexp">
        <img src={st} id="expimg"></img>
        <div id = "iteminfo">
        <h2 id="expitemtitle"> Scholarly Tuition </h2>
        <span id = "expspan">London, UK, 2019-2020</span>
        <h3> Tutor </h3>
        <ul className = "sublist">
          <li className = "subitem"> Tutored Maths for secondary school students </li>
        </ul>
        </div>
      </li>
    </ol>
    </div>
  )
}

export default Experience
