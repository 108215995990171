import React from 'react'
import "../styles/Contact.css"
import { LinkedIn } from '@material-ui/icons'
import { GitHub } from '@material-ui/icons'
import { Twitter } from '@material-ui/icons'
import { Instagram } from '@material-ui/icons'
import IconAnimaiton from '../animations/IconAnimation'
// src/Contact.js


function Contact() {

  return (
    <div className = "contact">
        <h1 className = "title">CONTACT</h1>
        <p className='email'>Please feel free to email me at darioclinemp3@gmail.com for any enquires or for my resume/cv</p>
        <div
              className= "contact-icon">
                <IconAnimaiton icon={ LinkedIn } link = 'https://www.linkedin.com/in/dario-cline-b96340168/' scaleFactor={1.2} baseScale = {6}/>
                <IconAnimaiton icon={ GitHub } link = 'https://github.com/IIDCII' scaleFactor={1.2} baseScale = {6}/>
                <IconAnimaiton icon={ Twitter } link = 'https://twitter.com/Dario_Cline' scaleFactor={1.2} baseScale = {5}/>
                <IconAnimaiton icon={ Instagram } link = 'https://www.instagram.com/dario_cline_?igsh=ajg2dGc1eG12Z3I1&utm_source=qr' scaleFactor={1.2} baseScale = {6}/>

            </div>
    </div>
  );
}

export default Contact;

