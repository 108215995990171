import React from 'react'
import ProjectItem from '../components/ProjectItem'
import "../styles/Projects.css"
import { ProjectList } from '../helpers/ProjectList'


function Projects() {
  return (
    <div className = "projects">
      <h1 className = "title">SOFTWARE PROJECTS</h1>
      <h3>Software projects either independent or uni assigned</h3>
      <div className ="projectList">
        {ProjectList.map((project, idx) => {
          return <ProjectItem id = {idx} name={project.name} image={project.image}></ProjectItem>
        })}
      </div>
      {/* <h1 className = "title">VIDEO EDITS</h1>
      <h3>Some examples of non-contracted work I've done  (there may be explicit language present)</h3>
      <div className = "videoList">
      <iframe width="560" height="315" src="https://www.youtube.com/embed/QGl8oZ1r_Yg?si=_OPxlamSnCro1dFm" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
      </div> */}
    </div>

  )
}

export default Projects


