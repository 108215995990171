import dbot from "../assets/dbot.png"
import mt from "../assets/moneytrees.png"
import website from "../assets/website.png"
import gan from "../assets/gan.png"
import depf from "../assets/depf.jpg"
import bert from "../assets/bert.png"
import mp from "../assets/mp.jpg"
import ps from "../assets/ps.jpg"
import hack from "../assets/hack.jpg"

export const ProjectList = [
    {
        name: "AI Twitter Bot", 
        image: dbot,
        desc: "Made a Twitter Bot using TensorFlow, Tweepy API and Google Cloud to respond to messages for me. I trained it creating a small dataset of how I would respond to people based on the usual responses I would get. It's currently innactive due to cost of running and I like to respond to people on Twitter",
        ref:"https://github.com/IIDCII/TwitterBot",
        refex:"https://x.com/PR_BOT347",
    },
    {
        name: "Money Trees", 
        image: mt,
        desc: "Uni Group project making an application that works as a voucher system for students and local businesses.",
        ref:"https://github.com/IIDCII/ESP-group-9",
    },
    {
        name: "This Website", 
        image: website,
        desc: "Used react.js with gsap to create this website",
        ref:"https://github.com/IIDCII/portfolio-website-basic",
    },
    {
        name: "Modified GAN model", 
        image: gan,
        desc: "Used the Pytorch library to create a GAN (Generative Adversarial Network) model to generate images based of the Fashion MNIST dataset",
        ref:"https://github.com/IIDCII/ML_CW_3-GAN/blob/main/MNIST%20Classifier.ipynb",
    },
    {
        name: "Modified BERT classifier model", 
        image: bert,
        desc: "Used the Pytorch library and a pretrained BERT transformer to create a model for univariate sentiment analysis for movies based of the IMDB dataset",
        ref:"https://github.com/IIDCII/NLP_CW",
    },
    {
        name: "Dropout Entropy Probability Function", 
        image: depf,
        desc: "For my 3rd yr dissertation project, I created a Dropout probability function that takes the input which is batch during training and adjusts the dropout based on the entropy of the data passed. The probability function is based of category learning in the brain by simulating plasticity and synaptic strength in the process.",
        ref:"https://github.com/IIDCII/diss_project",
        refex:"https://youtu.be/qVjKQlZgYLk",
    },
    {
        name: "Money Pool", 
        image: mp,
        desc: "Application made for group spending which creates an e-wallet per pool. The standout features are the verifcation and the ability to organise spending without including debt. Application still in the works.",
        ref:"https://github.com/IIDCII/Money-Pool",
        refex:"https://youtu.be/vmNilp8dO5w",
    },
    {
        name: "Pixel Sort plugin for Davinci Resolve", 
        image: ps,
        desc: "Made a pixel sort alogithm fusion plugin for Davinci Resolve to work in real time and so that you can adjust the amount of sorting that can be used in effects. Full release of the code is coming soon!",
        ref:"https://github.com/IIDCII/pixel-sort-plugin",
    },
    {
        name: "Hackathon Nightmare", 
        image: hack,
        desc: "For our Bath Hackathon project created a Mario party-style game selection of games that could be controlled by using fruit as a controller. We did this by building the game in Unity and using an arduino, measuring voltage differences for the controls. I helped fine tune the controls and made some of the minigames. The majority of all game design was also done by me.",
        ref:"https://github.com/G-Baudelaire/2024BathHackathon",
        refex:"https://devpost.com/software/hackathon-nightmare",
    },
]